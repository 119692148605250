@import "https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Montserrat&family=Bitter&display=swap";
body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Bitter, serif;
  font-size: .9rem;
}

a {
  color: #268bd2;
  text-decoration: none;
  transition: color .3s;
}

.night a {
  color: #a9d2ef;
}

#content {
  grid-column-gap: 3%;
  grid-template: "header header header header" 150px
                 ". nav content ." calc(100vh - 300px)
                 "footer footer footer footer" 150px
                 / auto minmax(200px, 300px) minmax(800px, 1000px) auto;
  transition: color .3s;
  display: grid;
}

@media (max-width: 1100px) {
  #content {
    grid-template-columns: 0 25% auto 0;
  }
}

@media (max-width: 768px) {
  #content {
    grid-template: "header header header" 100px
                   ". nav ." 10vh
                   ". content ." calc(90vh - 310px)
                   "footer footer footer" 150px
                   / 10% auto 10%;
    row-gap: 20px;
  }
}

#content.day {
  color: #073642;
}

#content.night {
  color: #eee8d5;
}

#night-sky-container, #day-sky-container {
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
}

#night-sky-grid-container, #day-sky-grid-container {
  height: 100vh;
  width: 100%;
  grid-template-rows: auto 100px;
  display: grid;
}

#day-sky {
  z-index: -1;
  background: linear-gradient(0deg, #89dddc 0, #f0f0f0 50px, #f0f0f0 calc(100vh - 200px), #89dddc 100%);
  grid-row: 1 / 2;
  transition: opacity .3s;
  display: grid;
}

#night-sky {
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(0deg, #4745ba 0, #0a001e 50px, #0a001e calc(100vh - 200px), #4745ba 100%);
  grid-row: 1 / 2;
  transition: opacity .3s;
  display: grid;
}

.stars {
  display: relative;
}

.star {
  opacity: .75;
  display: inline-block;
  position: absolute;
}

@keyframes starsOn {
  0% {
    opacity: 0;
    background-size: 0 0;
  }

  50% {
    opacity: 0;
    background-size: 0 0;
  }

  100% {
    opacity: 1;
    background-size: 100% 100%;
  }
}

@keyframes starsOff {
  from {
    background-size: 100% 100%;
  }

  to {
    background-size: 0 0;
  }
}

.day .star {
  background-repeat: no-repeat;
  animation-name: starsOff;
  animation-duration: .3s;
}

.night .star {
  background-repeat: no-repeat;
  animation-name: starsOn;
  animation-duration: .6s;
}

#header-area .star {
  animation: none;
}

.night .star-turquoise {
  background: radial-gradient(circle, #75f0e1 0%, rgba(255, 255, 255, 0) 75%) center no-repeat;
}

.night .star-lavendar {
  background: radial-gradient(circle, #b866ff 0%, rgba(255, 255, 255, 0) 75%) center no-repeat;
}

.night .star-pink {
  background: radial-gradient(circle, #e382be 0%, rgba(255, 255, 255, 0) 75%) center no-repeat;
}

.night .star-jasmine {
  background: radial-gradient(circle, #f5dd70 0%, rgba(255, 255, 255, 0) 75%) center no-repeat;
}

@keyframes sunmoonrise {
  from {
    top: 200px;
    bottom: -40px;
  }

  to {
    top: 10px;
    bottom: 100px;
  }
}

@keyframes sunmoonset {
  from {
    top: 10px;
    bottom: 100px;
  }

  to {
    top: 200px;
    bottom: -40px;
  }
}

#moon-large, #sun-large {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 450px;
}

@media (max-width: 768px) {
  #moon-large, #sun-large {
    left: 150px;
  }
}

.night #moon-large {
  animation: sunmoonrise .3s;
}

.night #sun-large, .day #moon-large {
  animation: sunmoonset .3s;
}

.day #sun-large {
  animation: sunmoonrise .3s;
}

@keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff, 0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }

  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff, 0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
  }
}

#header-area {
  width: 100%;
  height: 100px;
  grid-area: header;
  padding-bottom: .5rem;
  font-family: Montserrat, sans-serif;
  display: flex;
  position: fixed;
  top: 0;
}

#header-content-container {
  grid-column-gap: 3%;
  width: 100%;
  grid-template-columns: auto minmax(200px, 300px) minmax(800px, 1000px) auto;
  padding-top: .5rem;
  display: grid;
}

@media (max-width: 1100px) {
  #header-content-container {
    grid-template-columns: 0 25% auto 0;
  }
}

@media (max-width: 768px) {
  #header-content-container {
    grid-gap: 0;
    grid-template-columns: 13% auto 13%;
  }
}

@keyframes neonOn {
  0% {
    text-shadow: none;
  }

  50% {
    text-shadow: none;
  }

  55% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff, 0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }

  60% {
    text-shadow: none;
  }

  65% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff, 0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }

  70% {
    text-shadow: none;
  }

  100% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff, 0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }
}

#header-content {
  color: #073642;
  height: 100%;
  z-index: 50;
  background-color: rgba(38, 139, 210, .933);
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  transition: color .3s;
  display: flex;
  position: relative;
  top: 10px;
  left: 10px;
}

.night #header-content {
  color: #eee8d5;
  animation-name: neonOn, neon2;
  animation-duration: .6s, 1.5s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-direction: normal, alternate;
  animation-delay: 0s, .6s;
  box-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #fff, 0 0 4px #228dff, 0 0 7px #228dff, 0 0 8px #228dff, 0 0 10px #228dff, 0 0 15px #228dff;
}

#header-content-inner {
  z-index: 25;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #839496;
  flex-direction: column;
  grid-column-start: 2;
  transition: background-color .3s;
  display: flex;
}

#header-area .name {
  font-size: 2em;
}

#header-area .subname {
  margin-bottom: .5rem;
  font-size: 1em;
}

#header-grid {
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(100, 1fr);
  display: grid;
  position: absolute;
  overflow: hidden;
}

.header-grid-square {
  height: 16px;
  width: 16px;
  opacity: 0;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  margin: 1px;
  transition: opacity .3s;
  position: relative;
}

.header-grid-square-active {
  opacity: 1;
}

@keyframes gridSquarefadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.grid-square-active {
  border: 1px solid rgba(255, 255, 255, .1);
  animation: gridSquarefadeIn .3s;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, .1);
}

.grid-square-active-0 {
  background: rgba(209, 71, 25, .667);
}

.grid-square-active-1 {
  background: rgba(242, 102, 40, .667);
}

.grid-square-active-2 {
  background: rgba(140, 45, 39, .667);
}

.night .grid-square-active-0 {
  background: rgba(95, 12, 56, .667);
}

.night .grid-square-active-1 {
  background: rgba(141, 39, 76, .667);
}

.night .grid-square-active-2 {
  background: rgba(117, 20, 65, .667);
}

#life-button-container {
  flex-direction: row;
  justify-content: end;
  align-items: end;
  display: flex;
}

#life-button-container button {
  height: 1.5rem;
  width: 1.5rem;
  z-index: 1;
  background-color: #f0f0f0;
  border: none;
  border-radius: 3px;
  grid-template-rows: 100%;
  align-items: center;
  margin-left: 5px;
  padding: 2px;
  transition: background-color .3s;
  display: grid;
}

.night #life-button-container button {
  background-color: #0a001e;
}

#life-button-container button:hover {
  background-color: #93c7eb;
}

.night #life-button-container button {
  color: #eee8d5;
}

@media (max-width: 768px) {
  #life-button-container {
    display: none;
  }
}

.play-button-container, .pause-button-container, .reset-button-container {
  height: 100%;
  width: 100%;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  grid-row-start: 1;
  grid-column-start: 1;
  display: grid;
  position: relative;
}

.play-button-container > div, .pause-button-container > div, .reset-button-container > div {
  grid-row-start: 1;
  grid-column-start: 1;
  transition: opacity .3s;
  display: grid;
}

.play-button-container > div > img, .pause-button-container > div > img, .reset-button-container > div > img {
  height: 100%;
  width: 100%;
}

.day .life-icon-night, .night .life-icon, .life-play-button.play .pause-button-container, .life-play-button.pause .play-button-container {
  opacity: 0;
}

#footer-area {
  height: 150px;
  width: 100%;
  grid-area: footer;
  grid-template-rows: auto;
  grid-template-columns: auto 800px auto;
  display: grid;
}

@media (max-width: 800px) {
  #footer-area {
    grid-template-columns: 0 100% 0;
  }
}

#day-night-toggle-container {
  width: 100%;
  justify-content: center;
  display: flex;
}

#day-night-toggle {
  background-color: inherit;
  border: 1px solid rgba(38, 139, 210, .2);
  border-radius: 7px;
  align-items: center;
  padding: 2px;
  display: flex;
}

#day-night-toggle > div {
  width: 2rem;
  height: 2rem;
  opacity: 1;
  background-size: 100% 100%;
  transition: background-color .5s;
  display: flex;
}

#day-toggle {
  background: #fdc82e url("sun.f987fe89.svg");
  border-radius: 7px;
}

.night #day-toggle {
  background-color: rgba(253, 200, 46, 0);
}

.night #day-toggle:hover {
  background-color: rgba(253, 200, 46, .333);
}

#night-toggle {
  background: #839496 url("moon.81e0170b.svg");
  border-radius: 7px;
}

.day #night-toggle {
  background-color: rgba(131, 148, 150, 0);
}

.day #night-toggle:hover {
  background-color: rgba(131, 148, 150, .333);
}

#footer-area #day-ground {
  background: url("desert-ground-day.dd045d99.png") bottom / 100% 100px no-repeat;
  grid-row-start: 1;
  grid-column-start: 2;
  transition: opacity .3s;
  position: relative;
  overflow: hidden;
}

#footer-area #night-ground {
  background: url("desert-ground-night.432efcc5.png") bottom / 100% 100px no-repeat;
  grid-row-start: 1;
  grid-column-start: 2;
  transition: opacity .3s;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  #footer-area #day-ground, #footer-area #night-ground {
    background-size: 800px 100px;
  }
}

.footer-left {
  grid-area: 1 / 1 / 2 / 2;
  grid-template-rows: 50px 100px;
  transition: opacity .3s;
  display: grid;
}

.footer-left .footer-ground {
  grid-template-rows: 43% 5% 21% 19% 12%;
  display: grid;
}

.footer-right {
  grid-area: 1 / 3 / 2 / 4;
  grid-template-rows: 50px 100px;
  transition: opacity .3s;
  display: grid;
}

.footer-right .footer-ground {
  grid-template-rows: 51% 0 19% 11% 19%;
  display: grid;
}

.footer-side-day .desert-sky {
  background-color: #89dddc;
}

.footer-side-day .desert-ground-lighter {
  background-color: #fe8a41;
}

.footer-side-day .desert-ground-light {
  background-color: #f26628;
}

.footer-side-day .desert-ground-dark {
  background-color: #d14719;
}

.footer-side-day .desert-ground-darker {
  background-color: #8c2d27;
}

.footer-side-night .desert-sky {
  background-color: #4745ba;
}

.footer-side-night .desert-ground-lighter {
  background-color: #8d274c;
}

.footer-side-night .desert-ground-light {
  background-color: #751441;
}

.footer-side-night .desert-ground-dark {
  background-color: #5f0c38;
}

.footer-side-night .desert-ground-darker {
  background-color: #4d1832;
}

#menu-area {
  grid-area: nav;
  margin: 0;
  padding-left: 0;
  font-family: Montserrat, sans-serif;
}

#menu-area ul {
  padding: 0;
}

#menu-area ul li {
  background-color: rgba(187, 187, 187, .2);
  border: 1px solid rgba(131, 148, 150, .333);
  margin: .5rem 0;
  list-style: none;
  transition: background-color .1s ease-in;
}

#menu-area ul li:hover {
  background-color: rgba(38, 139, 210, .2);
  border: 1px solid rgba(38, 139, 210, .2);
}

#menu-area ul li.selected {
  color: #000;
  background-color: rgba(38, 139, 210, .2);
  border: 1px solid rgba(38, 139, 210, .2);
}

#menu-area ul li button {
  background-color: inherit;
  width: 100%;
  border: none;
  padding-top: .25rem;
  padding-bottom: .75rem;
  font-family: inherit;
  font-size: 1.3rem;
  transition: color .3s;
  position: relative;
  top: 5px;
  left: 5px;
}

.night #menu-area ul li.selected button {
  animation: neon2 1.5s ease-in-out infinite alternate;
}

.night #menu-area ul li button {
  color: #eee8d5;
}

@media (max-width: 768px) {
  #menu-area ul {
    grid-template-columns: repeat(3, 32%);
    column-gap: 2%;
    display: grid;
  }

  #menu-area ul li {
    display: grid;
  }

  #menu-area ul li button {
    color: inherit;
    font-size: .9rem;
  }
}

#sections-area {
  scrollbar-color: grey transparent;
  grid-area: content;
  overflow: auto;
}

#about-section {
  background-color: #f0f0f0;
  transition: background-color .3s;
}

.night #about-section {
  background-color: #0a001e;
}

#projects-section, #cv-section {
  background-color: #f0f0f0;
  transition: background-color .3s;
}

.night #projects-section, .night #cv-section {
  background-color: #0a001e;
}

#about-section {
  font-size: 1.1rem;
}

#about-section p:first-of-type {
  margin-top: .5rem;
}

#self-portrait {
  float: right;
  max-width: 300px;
  border: 1px solid #93a1a1;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 1rem;
  margin-right: 5px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
}

@media (max-width: 768px) {
  #self-portrait {
    box-shadow: none;
    max-width: 150px;
  }
}

#about-badges-container, #about-contact-container {
  justify-content: center;
  margin-bottom: 2rem;
  display: flex;
}

#about-contact {
  width: 50%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#about-badges {
  width: 50%;
  justify-content: space-evenly;
  display: flex;
}

#about-badges img {
  border: 1px solid #93a1a1;
  border-radius: 5px;
  margin-left: .5rem;
  margin-right: .5rem;
}

.italic {
  font-style: italic;
}

.project {
  border-bottom: 1px solid #657b83;
  margin-bottom: 2rem;
  padding: 0 .8rem 2rem;
}

.project:last-of-type {
  border-bottom: none;
}

.project-title {
  margin-top: 0;
  margin-bottom: .8rem;
  font-family: Montserrat, sans-serif;
  font-size: 1.7rem;
}

.project-tech {
  flex-wrap: wrap;
  margin-bottom: .8rem;
  font-family: Montserrat, sans-serif;
  font-size: 1.1rem;
  display: flex;
}

.project-tech span {
  margin: .1rem 1rem .1rem 0;
}

.project-tech img {
  height: 25px;
}

.project-git {
  margin-bottom: .8rem;
  font-family: Montserrat, sans-serif;
}

.project-imgs {
  max-width: 100%;
  justify-content: center;
  margin-bottom: .8rem;
  display: flex;
}

.project-imgs div {
  flex-basis: auto;
  margin: 0 1rem;
}

.project-imgs img {
  max-width: 600px;
  width: 100%;
  border: 1px solid #93a1a1;
  border-radius: 5px;
}

#personal-website img {
  transition: opacity .3s;
}

#personal-website .project-imgs {
  grid-template-rows: auto;
  grid-template-columns: auto;
  display: grid;
}

#personal-website .project-imgs div {
  grid-column-start: 0;
  grid-column-end: 1;
  grid-row-start: 0;
  grid-row-end: 1;
}

#personal-website .project-imgs div:first-of-type {
  z-index: 1;
}

#personal-website .project-imgs div:first-of-type img {
  opacity: 1;
}

#personal-website .project-imgs div:last-of-type {
  z-index: 0;
}

#personal-website .project-imgs div:last-of-type img {
  opacity: 0;
}

.night #personal-website .project-imgs div:first-of-type {
  z-index: 0;
}

.night #personal-website .project-imgs div:first-of-type img {
  opacity: 0;
}

.night #personal-website .project-imgs div:last-of-type {
  z-index: 1;
}

.night #personal-website .project-imgs div:last-of-type img {
  opacity: 1;
}

#cv-section {
  margin-right: 1.6rem;
}

#cv-header-section {
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.6rem;
  display: grid;
}

.cv-banner {
  font-family: Montserrat, sans-serif;
}

.cv-name {
  font-size: 2rem;
  font-weight: 900;
}

.cv-tagline {
  font-size: 1rem;
}

.cv-contact-info {
  justify-content: flex-end;
  justify-items: flex-end;
  display: grid;
}

@media (max-width: 768px) {
  #cv-section {
    font-size: .8rem;
  }

  #cv-header-section {
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    row-gap: .5rem;
  }

  .cv-contact-info {
    justify-content: center;
    justify-items: center;
  }
}

.cv-section-header {
  border-bottom: 1px solid #93a1a1;
  margin-bottom: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

#cv-skills-section {
  margin-bottom: 1.6rem;
}

.cv-skills-content {
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 35% auto;
  margin-left: .8rem;
  display: grid;
}

.cv-skills-content > div {
  padding-top: 4px;
}

@media (max-width: 768px) {
  .cv-skills-content {
    grid-template-rows: repeat(6, auto);
    grid-template-columns: auto;
  }
}

.cv-skills-title {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.cv-skills-tier {
  font-weight: 600;
}

#cv-exp-section, .cv-exp-position {
  margin-bottom: 1.6rem;
}

.cv-exp-header {
  grid-template-rows: auto auto;
  grid-template-columns: auto 35%;
  margin-bottom: .4rem;
  margin-left: .8rem;
  display: grid;
}

.cv-exp-employer {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.cv-exp-dates {
  justify-self: end;
}

.cv-exp-dates:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: right;
}

.cv-exp-dates:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.cv-exp-title {
  font-style: italic;
}

.cv-exp-location {
  justify-self: end;
  font-style: italic;
}

.cv-exp-summary {
  margin-left: .8rem;
}

.cv-exp-highlights {
  padding-left: .8rem;
}

.cv-exp-highlights li {
  margin-bottom: 4px;
}

.cv-exp-highlight-title {
  font-style: italic;
}

#cv-edu-section {
  margin-bottom: 1.6rem;
}

.cv-edu-entry {
  grid-template-rows: auto auto;
  grid-template-columns: auto 20%;
  margin-bottom: .8rem;
  margin-left: .8rem;
  display: grid;
}

.cv-edu-institution {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.cv-edu-dates {
  justify-content: end;
  display: grid;
}

@media (max-width: 768px) {
  .cv-edu-entry {
    grid-template-rows: auto auto auto;
    grid-template-columns: auto;
  }

  .cv-edu-dates {
    justify-content: start;
  }
}

#cv-pubs-section {
  margin-bottom: 4rem;
}

#cv-pubs-section ul {
  padding-left: .8rem;
  list-style: none;
}

#cv-pubs-section li {
  margin-bottom: .8rem;
}

.journal {
  font-style: italic;
}

.resume-link {
  text-align: center;
}

/*# sourceMappingURL=index.486c14a9.css.map */
