@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Montserrat&family=Bitter&display=swap");

$main-palette-color: #778ccc;
$solarized-base03: #002b36;
$solarized-base02: #073642;
$solarized-base01: #586e75;
$solarized-base00: #657b83;
$solarized-base0: #839496;
$solarized-base0-faded: #83949633;
$solarized-base0-half: #83949655;
$solarized-base0-trans: #83949600;
$solarized-base1: #93a1a1;
$solarized-base1-faded: #93a1a133;
$solarized-base2: #eee8d5;
$solarized-base3: #fdf6e3;
$solarized-yellow: #b58900;
$solarized-orange: #cb4b16;
$solarized-red: #dc322f;
$solarized-magenta: #d33682;
$solarized-violet: #6c71c4;
$solarized-violet-faded: #6c71c433;
$solarized-blue: #268bd2;
$solarized-blue-faded: #268bd233;
$solarized-blue-less-faded: #268bd2ee;
$solarized-blue-luminous: #93c7eb;
$solarized-blue-light: hsl(204.8, 69.4%, 80%);
$solarized-cyan: #2aa198;
$solarized-cyan-faded: #2aa19833;
$solarized-green: #859900;

/* $desert-sky-darker: #120036; */
$desert-sky-darker: #0a001e;
$desert-sky-dark: #4745ba;
$desert-sky-darker-faded: #0a001edd;
$desert-sky-dark-faded: #4745badd;
$desert-sky-light: #89dddc;
$desert-sky-lighter: #f0f0f0;
$desert-sky-light-faded: #89dddcdd;
$desert-sky-lighter-faded: #f0f0f0dd;

$desert-day-darker: #8c2d27;
$desert-day-dark: #d14719;
$desert-day-light: #f26628;
$desert-day-lighter: #fe8a41;
$desert-day-darker-faded: #8c2d27aa;
$desert-day-dark-faded: #d14719aa;
$desert-day-light-faded: #f26628aa;
$desert-day-lighter-faded: #fe8a41aa;

$desert-night-darker: #4d1832;
$desert-night-dark: #5f0c38;
$desert-night-light: #751441;
$desert-night-lighter: #8d274c;
$desert-night-darker-faded: #4d1832aa;
$desert-night-dark-faded: #5f0c38aa;
$desert-night-light-faded: #751441aa;
$desert-night-lighter-faded: #8d274caa;

$desert-sun-yellow: #fdc82e;
$desert-sun-yellow-half: #fdc82e55;
$desert-sun-yellow-faded: #fdc82e00;

// #2CE8D9, 44, 232, 217 (turquoise) 175, 80%, 54%
// #CB8FFF, 203, 143, 255 (lavendar) 272, 100%, 78%
// #EA9ECD, 234, 158, 205 (lavendar pink) 323, 64%, 77%
// #F5DC6E, 245, 220, 110 (jasmine) 49, 87%, 70%
$star-turquoise: hsl(175, 80%, 54%);
$star-lavendar: hsl(272, 100%, 78%);
$star-pink: hsl(323, 64%, 77%);
$star-jasmine: hsl(49, 87%, 70%);

$medium-screen-break: 1100px;
$small-screen-break: 768px;

$transition-time: 0.3s;

body {
  background-color: #ffffff;
  font-family: "Bitter", serif;
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  transition: color $transition-time;
  color: $solarized-blue;
}
.night a {
  color: $solarized-blue-light;
}

#content {
  display: grid;
  grid-column-gap: 3%;
  // row-gap: 50px;
  grid-template-rows: 150px calc(100vh - 300px) 150px;
  grid-template-columns: auto minmax(200px, 300px) minmax(800px, 1000px) auto;
  grid-template-areas:
    "header header header header"
    ". nav content ."
    "footer footer footer footer";
  transition: color $transition-time;
}
@media (max-width: $medium-screen-break) {
  #content {
    grid-template-columns: 0 25% auto 0;
  }
}
@media (max-width: $small-screen-break) {
  #content {
    grid-template-columns: 10% auto 10%;
    grid-template-rows: 100px 10vh calc(90vh - 310px) 150px;
    grid-template-areas:
      "header header header"
      ". nav ."
      ". content ."
      "footer footer footer";
    row-gap: 20px;
  }
}
#content.day {
  color: $solarized-base02;
}
#content.night {
  color: $solarized-base2;
}

/*******/
/* Sky */
/*******/
#night-sky-container,
#day-sky-container {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
#night-sky-grid-container,
#day-sky-grid-container {
  display: grid;
  grid-template-rows: auto 100px;
  height: 100vh;
  width: 100%;
}
#day-sky {
  background: linear-gradient(
    0deg,
    $desert-sky-light 0,
    $desert-sky-lighter 50px,
    $desert-sky-lighter calc(100vh - 200px),
    $desert-sky-light 100%
  );
  display: grid;
  grid-row-start: 1;
  grid-row-end: 2;
  z-index: -1;
  transition: opacity $transition-time;
}
#night-sky {
  background: linear-gradient(
    0deg,
    $desert-sky-dark 0,
    $desert-sky-darker 50px,
    $desert-sky-darker calc(100vh - 200px),
    $desert-sky-dark 100%
  );
  display: grid;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: opacity $transition-time;
}
.stars {
  display: relative;
}
.star {
  display: inline-block;
  position: absolute;
  opacity: 75%;
}
@keyframes starsOn {
  0% {
    background-size: 0 0;
    opacity: 0%;
  }
  50% {
    background-size: 0 0;
    opacity: 0%;
  }
  100% {
    background-size: 100% 100%;
    opacity: 100%;
  }
}
@keyframes starsOff {
  from {
    background-size: 100% 100%;
  }
  to {
    background-size: 0 0;
  }
}
.day .star {
  animation-name: starsOff;
  animation-duration: $transition-time;
  background-repeat: no-repeat;
}
.night .star {
  animation-name: starsOn;
  animation-duration: calc($transition-time * 2);
  background-repeat: no-repeat;
}
#header-area .star {
  animation: none;
}
.night .star-turquoise {
  background: radial-gradient(
    circle,
    hsla(173, 80%, 70%, 100%) 0%,
    hsla(173, 80%, 100%, 0%) 75%
  );
  background-repeat: no-repeat;
  background-position: center;
}
.night .star-lavendar {
  background: radial-gradient(
    circle,
    hsla(272, 100%, 70%, 100%) 0%,
    hsla(272, 100%, 100%, 0%) 75%
  );
  background-repeat: no-repeat;
  background-position: center;
}
.night .star-pink {
  background: radial-gradient(
    circle,
    hsla(323, 64%, 70%, 100%) 0%,
    hsla(323, 64%, 100%, 0%) 75%
  );
  background-repeat: no-repeat;
  background-position: center;
}
.night .star-jasmine {
  background: radial-gradient(
    circle,
    hsla(49, 87%, 70%, 100%) 0%,
    hsla(49, 87%, 100%, 0%) 75%
  );
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes sunmoonrise {
  from {
    top: 200;
    bottom: -40;
  }
  to {
    top: 10;
    bottom: 100;
  }
}
@keyframes sunmoonset {
  from {
    top: 10;
    bottom: 100;
  }
  to {
    top: 200;
    bottom: -40;
  }
}
#moon-large,
#sun-large {
  display: inline-block;
  position: absolute;
  top: 10;
  left: 450;
}
@media (max-width: $small-screen-break) {
  #moon-large,
  #sun-large {
    left: 150;
  }
}
.night #moon-large {
  animation: sunmoonrise $transition-time;
}
.night #sun-large {
  animation: sunmoonset $transition-time;
}
.day #moon-large {
  animation: sunmoonset $transition-time;
}
.day #sun-large {
  animation: sunmoonrise $transition-time;
}

/**********/
/* Header */
/**********/

@keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
      0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff,
      0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
  }
}

#header-area {
  grid-area: header;
  display: flex;
  font-family: "Montserrat", sans-serif;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  padding-bottom: 0.5rem;
}
#header-content-container {
  display: grid;
  grid-column-gap: 3%;
  grid-template-columns: auto minmax(200px, 300px) minmax(800px, 1000px) auto;
  padding-top: 0.5rem;
  width: 100%;
}
@media (max-width: $medium-screen-break) {
  #header-content-container {
    grid-template-columns: 0 25% auto 0;
  }
}
@media (max-width: $small-screen-break) {
  #header-content-container {
    grid-template-columns: 13% auto 13%;
    grid-gap: 0;
  }
}

@keyframes neonOn {
  0% {
    text-shadow: none;
  }
  50% {
    text-shadow: none;
  }
  55% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
      0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }
  60% {
    text-shadow: none;
  }
  65% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
      0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }
  70% {
    text-shadow: none;
  }
  100% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
      0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }
}

#header-content {
  background-color: $solarized-blue-less-faded;
  // background-color: $solarized-blue-faded;
  color: $solarized-base02;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 1rem;
  transition: color $transition-time;
  z-index: 50;
  position: relative;
  top: 10px;
  left: 10px;
}
.night #header-content {
  color: $solarized-base2;
  animation-name: neonOn, neon2;
  animation-duration: calc($transition-time * 2), 1.5s;
  animation-delay: 0s, calc($transition-time * 2);
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-direction: normal, alternate;
  box-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #fff, 0 0 4px #228dff,
    0 0 7px #228dff, 0 0 8px #228dff, 0 0 10px #228dff, 0 0 15px #228dff;
}
#header-content-inner {
  background-color: #00000000;
  border: 1px solid $solarized-base0;
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  transition: background-color $transition-time;
  z-index: 25;
}
#header-area {
  .name {
    font-size: 2em;
  }
  .subname {
    font-size: 1em;
    margin-bottom: 0.5rem;
  }
}

#header-grid {
  display: grid;
  grid-template-columns: repeat(100, 1fr);
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.header-grid-square {
  border: 1px solid #00000000;
  border-radius: 5px;
  height: 16px;
  position: relative;
  margin: 1px;
  width: 16px;
  opacity: 0;
  transition: opacity $transition-time;
}
.header-grid-square-active {
  opacity: 1;
}
@keyframes gridSquarefadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.grid-square-active {
  animation: gridSquarefadeIn $transition-time;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}
.grid-square-active-0 {
  background: $desert-day-dark-faded;
}
.grid-square-active-1 {
  background: $desert-day-light-faded;
}
.grid-square-active-2 {
  background: $desert-day-darker-faded;
}
.night {
  .grid-square-active-0 {
    background: $desert-night-dark-faded;
  }
  .grid-square-active-1 {
    background: $desert-night-lighter-faded;
  }
  .grid-square-active-2 {
    background: $desert-night-light-faded;
  }
}
#life-button-container {
  align-items: end;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
#life-button-container button {
  align-items: center;
  background-color: $desert-sky-lighter;
  border: none;
  border-radius: 3px;
  display: grid;
  grid-template-rows: 100%;
  height: 1.5rem;
  margin-left: 5px;
  padding: 2px;
  transition: background-color $transition-time;
  width: 1.5rem;
  z-index: 1;
}
.night #life-button-container button {
  background-color: $desert-sky-darker;
}
#life-button-container button:hover {
  background-color: $solarized-blue-luminous;
}
.night #life-button-container button {
  color: $solarized-base2;
}
@media (max-width: $small-screen-break) {
  #life-button-container {
    display: none;
  }
}
.play-button-container,
.pause-button-container,
.reset-button-container {
  display: grid;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  > div {
    display: grid;
    grid-column-start: 1;
    grid-row-start: 1;
    transition: opacity $transition-time;
    > img {
      height: 100%;
      width: 100%;
    }
  }
}
.day .life-icon-night {
  opacity: 0;
}

.night .life-icon {
  opacity: 0;
}

.life-play-button {
  &.play {
    .pause-button-container {
      opacity: 0;
    }
  }
  &.pause {
    .play-button-container {
      opacity: 0;
    }
  }
}

/**********/
/* Footer */
/**********/

#footer-area {
  display: grid;
  grid-area: footer;
  grid-template-columns: auto 800px auto;
  grid-template-rows: auto;
  height: 150px;
  width: 100%;
}
@media (max-width: 800px) {
  #footer-area {
    grid-template-columns: 0 100% 0;
  }
}
#day-night-toggle-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

#day-night-toggle {
  align-items: center;
  background-color: inherit;
  border: 1px solid $solarized-blue-faded;
  border-radius: 7px;
  display: flex;
  padding: 2px;
  > div {
    background-size: 100% 100%;
    display: flex;
    width: 2rem;
    height: 2rem;
    opacity: 1;
    transition: background-color 0.5s;
  }
}
#day-toggle {
  background: url("../img/controls/sun.svg");
  background-color: $desert-sun-yellow;
  border-radius: 7px;
}
.night {
  #day-toggle {
    background-color: $desert-sun-yellow-faded;
    &:hover {
      background-color: $desert-sun-yellow-half;
    }
  }
}
#night-toggle {
  background: url("../img/controls/moon.svg");
  background-color: $solarized-base0;
  border-radius: 7px;
}
.day #night-toggle {
  background-color: $solarized-base0-trans;
  &:hover {
    background-color: $solarized-base0-half;
  }
}
#footer-area {
  #day-ground {
    background: url("../img/desert-ground-day.png");
    background-size: 100% 100px;
    background-repeat: no-repeat;
    background-position: bottom;
    grid-column-start: 2;
    grid-row-start: 1;
    transition: opacity $transition-time;
    position: relative;
    overflow: hidden;
  }
  #night-ground {
    background: url("../img/desert-ground-night.png");
    background-size: 100% 100px;
    background-repeat: no-repeat;
    background-position: bottom;
    grid-column-start: 2;
    grid-row-start: 1;
    transition: opacity $transition-time;
    position: relative;
    overflow: hidden;
  }
}
@media (max-width: $small-screen-break) {
  #footer-area {
    #day-ground {
      background-size: 800px 100px;
    }
    #night-ground {
      background-size: 800px 100px;
    }
  }
}
.footer-left {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-template-rows: 50px 100px;
  transition: opacity $transition-time;
  .footer-ground {
    display: grid;
    grid-template-rows: 43% 5% 21% 19% 12%;
  }
}
.footer-right {
  display: grid;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-template-rows: 50px 100px;
  transition: opacity $transition-time;
  .footer-ground {
    display: grid;
    grid-template-rows: 51% 0 19% 11% 19%;
  }
}
.footer-side-day {
  .desert-sky {
    background-color: $desert-sky-light;
  }
  .desert-ground-lighter {
    background-color: $desert-day-lighter;
  }
  .desert-ground-light {
    background-color: $desert-day-light;
  }
  .desert-ground-dark {
    background-color: $desert-day-dark;
  }
  .desert-ground-darker {
    background-color: $desert-day-darker;
  }
}
.footer-side-night {
  .desert-sky {
    background-color: $desert-sky-dark;
  }
  .desert-ground-lighter {
    background-color: $desert-night-lighter;
  }
  .desert-ground-light {
    background-color: $desert-night-light;
  }
  .desert-ground-dark {
    background-color: $desert-night-dark;
  }
  .desert-ground-darker {
    background-color: $desert-night-darker;
  }
}

/********/
/* Menu */
/********/

#menu-area {
  font-family: "Montserrat", sans-serif;
  grid-area: nav;
  margin: 0;
  padding-left: 0;
  ul {
    padding: 0;
    li {
      background-color: #bbbbbb33;
      border: 1px solid $solarized-base0-half;
      list-style: none;
      margin: 0.5rem 0;
      transition: background-color 100ms ease-in;
      &:hover {
        background-color: $solarized-blue-faded;
        border: 1px solid $solarized-blue-faded;
      }
      &.selected {
        background-color: $solarized-blue-faded;
        border: 1px solid $solarized-blue-faded;
        color: black;
      }
      button {
        background-color: inherit;
        border: none;
        font-family: inherit;
        font-size: 1.3rem;
        left: 5px;
        padding-bottom: 0.75rem;
        padding-top: 0.25rem;
        position: relative;
        top: 5px;
        transition: color $transition-time;
        width: 100%;
      }
    }
  }
}
.night #menu-area ul li {
  &.selected {
    button {
      animation: neon2 1.5s ease-in-out infinite alternate;
    }
  }
}
.night #menu-area ul li button {
  color: $solarized-base2;
}

@media (max-width: $small-screen-break) {
  #menu-area ul {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    column-gap: 2%;
    li {
      display: grid;
      button {
        color: inherit;
        font-size: 0.9rem;
      }
    }
  }
}

/************/
/* Sections */
/************/

#sections-area {
  grid-area: content;
  overflow: auto;
  scrollbar-color: grey transparent;
}

#about-section {
  background-color: $desert-sky-lighter;
  transition: background-color $transition-time;
}
.night #about-section {
  background-color: $desert-sky-darker;
}
#projects-section,
#cv-section {
  background-color: $desert-sky-lighter;
  transition: background-color $transition-time;
}
.night #projects-section,
.night #cv-section {
  background-color: $desert-sky-darker;
}

/*********/
/* About */
/*********/
#about-section {
  font-size: 1.1rem;
  p:first-of-type {
    margin-top: 0.5rem;
  }
}
#self-portrait {
  border: 1px solid $solarized-base1;
  border-radius: 5px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  float: right;
  margin-left: 1rem;
  margin-right: 5px;
  margin-bottom: 10px;
  max-width: 300px;
}
@media (max-width: $small-screen-break) {
  #self-portrait {
    box-shadow: none;
    max-width: 150px;
  }
}
#about-badges-container,
#about-contact-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
#about-contact {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
}
#about-badges {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  img {
    border: 1px solid $solarized-base1;
    border-radius: 5px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
.italic {
  font-style: italic;
}

/************/
/* Projects */
/************/

.project {
  border-bottom: 1px solid $solarized-base00;
  margin-bottom: 2rem;
  padding: 0 0.8rem 2rem 0.8rem;
}
.project:last-of-type {
  border-bottom: none;
}
.project-title {
  font-family: "Montserrat", sans-serif;
  font-size: 1.7rem;
  margin-bottom: 0.8rem;
  margin-top: 0;
}
.project-tech {
  display: flex;
  flex-wrap: wrap;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
  span {
    margin: 0.1rem 1rem 0.1rem 0;
  }
  img {
    height: 25px;
  }
}
.project-git {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0.8rem;
}
.project-imgs {
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem;
  max-width: 100%;
  div {
    flex-basis: auto;
    margin: 0 1rem;
  }
  img {
    border: 1px solid $solarized-base1;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
  }
}

#personal-website img {
  transition: opacity $transition-time;
}
#personal-website .project-imgs {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  div {
    grid-column-start: 0;
    grid-column-end: 1;
    grid-row-start: 0;
    grid-row-end: 1;
    &:first-of-type {
      z-index: 1;
      img {
        opacity: 1;
      }
    }
    &:last-of-type {
      z-index: 0;
      img {
        opacity: 0;
      }
    }
  }
}
.night #personal-website .project-imgs {
  div {
    &:first-of-type {
      z-index: 0;
      img {
        opacity: 0;
      }
    }
    &:last-of-type {
      z-index: 1;
      img {
        opacity: 1;
      }
    }
  }
}
/******/
/* CV */
/******/
#cv-section {
  margin-right: 1.6rem;
}
#cv-header-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.6rem;
}
.cv-banner {
  font-family: "Montserrat", sans-serif;
}
.cv-name {
  font-size: 2rem;
  font-weight: 900;
}
.cv-tagline {
  font-size: 1rem;
}
.cv-contact-info {
  display: grid;
  justify-content: flex-end;
  justify-items: flex-end;
}
@media (max-width: $small-screen-break) {
  #cv-section {
    font-size: 0.8rem;
  }
  #cv-header-section {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    row-gap: 0.5rem;
  }
  .cv-contact-info {
    justify-content: center;
    justify-items: center;
  }
}

.cv-section-header {
  border-bottom: 1px solid $solarized-base1;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 8px;
}

/* CV Skills */
#cv-skills-section {
  margin-bottom: 1.6rem;
}
.cv-skills-content {
  display: grid;
  grid-template-columns: 35% auto;
  grid-template-rows: 1fr 1fr 1fr;
  margin-left: 0.8rem;
  > div {
    padding-top: 4px;
  }
}
@media (max-width: $small-screen-break) {
  .cv-skills-content {
    grid-template-columns: auto;
    grid-template-rows: repeat(6, auto);
  }
}
.cv-skills-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.cv-skills-tier {
  font-weight: 600;
}

/* CV Exp */
#cv-exp-section {
  margin-bottom: 1.6rem;
}
.cv-exp-position {
  margin-bottom: 1.6rem;
}
.cv-exp-header {
  display: grid;
  grid-template-columns: auto 35%;
  grid-template-rows: auto auto;
  margin-bottom: 0.4rem;
  margin-left: 0.8rem;
}
.cv-exp-employer {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.cv-exp-dates {
  justify-self: end;
  text-align: end;
}
.cv-exp-title {
  font-style: italic;
}
.cv-exp-location {
  font-style: italic;
  justify-self: end;
}
.cv-exp-summary {
  margin-left: 0.8rem;
}
.cv-exp-highlights {
  padding-left: 0.8rem;
  li {
    margin-bottom: 4px;
  }
}
.cv-exp-highlight-title {
  font-style: italic;
}

/* CV Edu */
#cv-edu-section {
  margin-bottom: 1.6rem;
}
.cv-edu-entry {
  display: grid;
  grid-template-columns: auto 20%;
  grid-template-rows: auto auto;
  margin-left: 0.8rem;
  margin-bottom: 0.8rem;
}
.cv-edu-institution {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.cv-edu-dates {
  display: grid;
  justify-content: end;
}
@media (max-width: $small-screen-break) {
  .cv-edu-entry {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
  }
  .cv-edu-dates {
    justify-content: start;
  }
}

/* CV Pubs */
#cv-pubs-section {
  margin-bottom: 4rem;
  ul {
    list-style: none;
    padding-left: 0.8rem;
  }
  li {
    margin-bottom: 0.8rem;
  }
}
.journal {
  font-style: italic;
}
.resume-link {
  text-align: center;
}
